document.addEventListener('DOMContentLoaded', () => {
    const startpageBanner = document.getElementById('startpagebanner');
    const sectionToCanvasMap = {
        "startpagebanner": "canvasArtStart", // Map für Banner zu Canvas
    };

    let currentP5Instance = null;
    let currentScript = null;

    // Funktion zum Entfernen der alten Instanz und des Canvas
    const removeCanvas = () => {
        if (currentP5Instance) {
            try {
                currentP5Instance.remove();
                console.log('p5-Instanz entfernt');
            } catch (error) {
                console.warn('Fehler beim Entfernen der p5-Instanz:', error);
            }
            currentP5Instance = null;
        }

        // Entferne das Skript, falls geladen
        if (currentScript) {
            const script = [...document.scripts].find(s => s.src.includes(currentScript));
            if (script) {
                script.remove();
                console.log(`Skript entfernt: ${currentScript}`);
            }
            currentScript = null;
        }
    };

    // Funktion zur Initialisierung des Canvas
    const initializeCanvas = (value) => {
        if (!value) return;

        console.log(`Initialisiere Canvas: ${value}`);

        const scriptSrcMap = {
            "canvasArtStart": "/vendor/math/starField.js", // Pfad zur JS-Datei
        };

        const scriptSrc = scriptSrcMap[value];
        if (!scriptSrc) {
            console.warn(`Kein Skript für ${value} gefunden.`);
            return;
        }

        // Wenn das Skript bereits geladen ist, starte direkt die p5-Instanz
        const existingScript = [...document.scripts].find(script => script.src.includes(scriptSrc));
        if (existingScript) {
            console.log(`Skript bereits geladen: ${scriptSrc}`);
            loadP5Instance(value);
            return;
        }

        // Wenn das Skript noch nicht geladen ist, lade es
        const script = document.createElement('script');
        script.src = scriptSrc;
        script.onload = () => {
            console.log(`Skript erfolgreich geladen: ${scriptSrc}`);
            currentScript = scriptSrc;
            loadP5Instance(value);
        };
        document.body.appendChild(script);
    };

    // Funktion zum Laden der p5-Instanz
    const loadP5Instance = (value) => {
        const sketchInstanceMap = {
            "canvasArtStart": window.canvasArtStart, // `canvasArt1` muss in der geladenen JS-Datei definiert sein
        };

        const sketchInstance = sketchInstanceMap[value];
        if (!sketchInstance) {
            console.warn(`Keine p5-Instanz für ${value} gefunden.`);
            return;
        }

        // Entferne vorherige Instanz, falls vorhanden
        removeCanvas();

        console.log('Starte neue p5-Instanz...');
        currentP5Instance = new p5((p) => {
            p.preload = () => sketchInstance.preload(p);
            p.setup = () => sketchInstance.setup(p);
            p.draw = () => sketchInstance.draw(p);
        }, "startpagebanner"); // Canvas in `startpagebanner` einfügen
    };

    // IntersectionObserver zur Überwachung des Startpage-Banners
    const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
            if (entry.isIntersecting && entry.intersectionRatio >= 0.2) {
                console.log('Startpage-Banner sichtbar, p5-Instanz wird erstellt...');
                initializeCanvas(sectionToCanvasMap["startpagebanner"]);
            } else {
                console.log('Startpage-Banner nicht sichtbar, p5-Instanz wird entfernt...');
                removeCanvas();
            }
        });
    }, { threshold: 0.2 });

    // Beobachtung starten
    if (startpageBanner) {
        observer.observe(startpageBanner);
    } else {
        console.error('Startpage-Banner nicht gefunden.');
    }
});









document.addEventListener('DOMContentLoaded', () => {
    const mainDropdownButton = document.getElementById('main-dropdown-button');
const mainDropdownMenu = document.getElementById('main-dropdown-menu');
const subDropdownButton = document.getElementById('sub-dropdown-button');
const subDropdownMenu = document.getElementById('sub-dropdown-menu');
const subCategoryBoxes = document.getElementById('sub-category-boxes');
    const formulaContainer = document.getElementById('formula-container');
    const aValueContainer = document.getElementById('a-value-container');

     let currentScriptTag = null;
    let scriptLoaded = false; // Status des Skriptladens
    let currentSubCategoryValue = ''; // Aktueller Wert der Unterkategorie

    // Canvas entfernen
    const removeCanvas = () => {
console.log('Entferne Canvas');
const canvasElements = document.querySelectorAll('#canvas-container canvas');
canvasElements.forEach(canvas => canvas.remove());
animationCount = 0;

if (window.currentP5Instance) {
    window.currentP5Instance.remove(); // Entferne die p5-Instanz
    window.currentP5Instance = null;
}
scriptLoaded = false; // Setze scriptLoaded zurück, um das Skript neu zu laden
};
    // Skript laden
    const loadScript = (src, callback) => {
if (scriptLoaded) {
    console.log(`Skript bereits geladen: ${src}`);
    callback();
    return;
}

        console.log(`Lade Skript: ${src}`);

        const script = document.createElement('script');
script.src = src; // Kein Cache-Busting
script.onload = () => {
    console.log(`Skript geladen: ${src}`);
    scriptLoaded = true;
    callback();
};
script.onerror = () => console.error(`Fehler beim Laden des Skripts: ${src}`);
document.head.appendChild(script);
};
    // Canvas initialisieren
    const initializeCanvas = (scriptPath, value) => {
// Wenn sich die Subkategorie nicht geändert hat, abbrechen
if (value === currentSubCategoryValue) {
    console.log('Wert hat sich nicht geändert. Keine Aktion erforderlich.');
    return;
}

currentSubCategoryValue = value; // Setze den neuen Wert als aktuellen Wert

removeCanvas(); // Entferne den vorherigen Canvas
loadScript(scriptPath, () => {
    if (window.initializeCanvas) {
        window.initializeCanvas(value); // Rufe die Initialisierungsfunktion auf
    }
});
};
const setupMainDropdownOptions = () => {



const translations = {
de: {
    development: "Entwicklung",
    moebius: "Möbius",
    smiley: "Smiley",
    portal: "Portal",
    static: "Statisch",
    experimental: "Experimentell",
    partialdimension: "Partielle Dimension",
    blossom: "Blüte",
    wave: "Welle",
    circle: "Kreis",
    opera: "Opera",

},
en: {
    development: "Development",
    moebius: "Möbius",
    smiley: "Smiley",
    portal: "Portal",
    static: "Static",
    experimental: "Experimental",


    partialdimension: "Partial Dimension",
    blossom: "Blossom",
    wave: "Wave",
    circle: "Circle",
    opera: "Opera"
}
};


const categories = {
    "portal": translations[currentLocale].portal,

    "moebius": translations[currentLocale].moebius,
    "smiley": translations[currentLocale].smiley,
    "static": translations[currentLocale].static,
    "partialdimension": translations[currentLocale].partialdimension,
    "blossom": translations[currentLocale].blossom,

    "opera": translations[currentLocale].opera,
     "development": translations[currentLocale].development,
    "experimental": translations[currentLocale].experimental
};

// Sicherstellen, dass das Menü zunächst leer ist
mainDropdownMenu.innerHTML = '';
Object.keys(categories).forEach((category, index) => {
        const categoryElement = document.createElement('div');
        categoryElement.textContent = categories[category]; // Use the translated name
        categoryElement.className = 'z-20 rounded text-gray-900 dark:text-white px-4 py-2 cursor-pointer border-2 border-gray-400 dark:border-gray-600 dark:hover:border-white hover:border-2 hover:border-gray-400 hover:bg-gray-200 dark:hover:bg-gray-700 focus:bg-gray-700 bg-white dark:bg-gray-800 border-opacity-60 duration-200';

        // Default selected category
        if (index === 0) {
            categoryElement.classList.add('bg-gray-300', 'dark:bg-gray-500', 'border-black', 'dark:border-white');
            categoryElement.classList.remove('border-gray-400', 'dark:border-gray-600', 'bg-white', 'dark:bg-gray-800');
            handleMainCategorySelection(category);
        }

        // Add click event
        categoryElement.addEventListener('click', () => {
            const allCategoryElements = mainDropdownMenu.querySelectorAll('div');
            allCategoryElements.forEach(el => {
                el.classList.remove('bg-gray-300', 'dark:bg-gray-500', 'border-black', 'dark:border-white');
                el.classList.add('border-gray-400', 'dark:border-gray-600', 'bg-white', 'dark:bg-gray-800');
            });

            // Highlight selected category
            categoryElement.classList.add('bg-gray-300', 'dark:bg-gray-500', 'border-black', 'dark:border-white');
            categoryElement.classList.remove('border-gray-400', 'dark:border-gray-600', 'bg-white', 'dark:bg-gray-800');
            handleMainCategorySelection(category);
            mainDropdownMenu.classList.add('hidden'); // Close main menu
        });

        mainDropdownMenu.appendChild(categoryElement);
    });

    // Set default selection
    handleMainCategorySelection("portal");

};
const setupSubCategoryBoxes = (subCategories) => {
subCategoryBoxes.innerHTML = '';

subCategories.forEach((option, index) => {
    const boxElement = document.createElement('div');
    boxElement.className = 'z-20 w-full rounded text-gray-900 dark:text-white px-4 py-2 cursor-pointer border-2 border-gray-400 dark:border-gray-600 dark:hover:border-white hover:border-2 hover:border-gray-400 hover:bg-gray-400 dark:hover:bg-gray-700 focus:bg-gray-700 bg-white dark:bg-gray-800 border-opacity-60 duration-200';
    boxElement.textContent = option.label;
    boxElement.style.minWidth = '15x';
    // Standardmäßig aktiviertes Subkategorie-Element
    if (index === 0) {
        boxElement.classList.add('bg-gray-300', 'dark:bg-gray-500', 'border-black', 'dark:border-white');
        boxElement.classList.remove('border-gray-400', 'dark:border-gray-600', 'bg-white', 'dark:bg-gray-800');
        initializeCanvas(option.scriptPath, option.value);
        subDropdownButton.textContent = option.label;
    }

    boxElement.addEventListener('click', () => {
        const allBoxes = subCategoryBoxes.querySelectorAll('div');
        allBoxes.forEach(box => {
            box.classList.remove('bg-gray-300', 'dark:bg-gray-500', 'border-black', 'dark:border-white');
            box.classList.add('border-gray-400', 'dark:border-gray-600', 'bg-white', 'dark:bg-gray-800'); // Setze die Standard-Hintergrundfarbe und -border für nicht ausgewählte Elemente
        });

        // Aktives Element erhält helle Hintergründe und schwarze Border im Light Mode bzw. weiße Border im Dark Mode
        boxElement.classList.add('bg-gray-300', 'dark:bg-gray-500', 'border-black', 'dark:border-white');
        boxElement.classList.remove('border-gray-400', 'dark:border-gray-600', 'bg-white', 'dark:bg-gray-800');
        subDropdownButton.textContent = option.label;

        initializeCanvas(option.scriptPath, option.value);
        subDropdownMenu.classList.add('hidden'); // Schließe das Untermenü nach Auswahl
    });

    subCategoryBoxes.appendChild(boxElement);
});


};




    // Hauptkategorie-Auswahl
    const handleMainCategorySelection = (category) => {


        const translations = {
de: {
    development: "Entwicklung",


    opera: "Opera",



    plain: "Normal",
    transversal: "Transversal",
    transversal2: "Transversal 2",
    moebius: "Möbius",
    smiley: "Smiley",
    portal: "Portal",
    static: "Statisch",
    partialdimension: "Partielle Dimension",
    blossom: "Blüte",
    wave: "Welle",
    circle: "Kreis",
    opera: "Oper",
    experimental: "Experimentell",
    experimental1: "Experimentell 1",
    experimental2: "Experimentell 2",
    axial: "Axial",
    axial2: "Axial 2",
    biaxial: "Biaxial",
    bidirectional: "Bidirektional",
    bidirectional2: "Bidirektional 2",
    bidirectional3: "Bidirektional 3",
    twoD: "2D",
    threeD: "3D",
    regular: "Regulär",
    staticLabel: "Statisch",
     multiplication: "Multiplikation"
},
en: {
    development: "Development",







    partialdimension: "Partial Dimension",
    blossom: "Blossom",
    wave: "Wave",
    circle: "Circle",
    opera: "Opera",

    plain: "Plain",
    transversal: "Transversal",
    transversal2: "Transversal 2",
    moebius: "Möbius",
    smiley: "Smiley",
    portal: "Portal",
    static: "Static",
    partialdimension: "Partial Dimension",
    blossom: "Blossom",
    wave: "Wave",
    circle: "Circle",
    opera: "Opera",
    experimental: "Experimental",
    experimental1: "Experimental 1",
    experimental2: "Experimental 2",
    axial: "Axial",
    axial2: "Axial 2",
    biaxial: "Biaxial",
    bidirectional: "Bidirectional",
    bidirectional2: "Bidirectional 2",
    bidirectional3: "Bidirectional 3",
    twoD: "2D",
    threeD: "3D",
    regular: "Regular",
    staticLabel: "Static",
    multiplication: "Multiplication"
}
};





mainDropdownButton.textContent = translations[currentLocale][category]; // Verwende die Übersetzung hier


const subCategories = {
    "portal": [
        { label: translations[currentLocale].plain, scriptPath: '/vendor/math/mathCanvasMaster.js', value: 'portalnew' },
        { label: translations[currentLocale].experimental, scriptPath: '/vendor/math/mathCanvasMaster.js', value: 'portal' }
    ],
    "moebius": [
        { label: translations[currentLocale].plain, scriptPath: '/vendor/math/mathCanvasMaster.js', value: 'moebiusplain' },
    { label: translations[currentLocale].transversal, scriptPath: '/vendor/math/mathCanvasMaster.js', value: 'moebiustrans' },
    { label: translations[currentLocale].transversal2, scriptPath: '/vendor/math/mathCanvasMaster.js', value: 'moebiustrans2' },
            { label: translations[currentLocale].axial, scriptPath: '/vendor/math/mathCanvasMaster.js', value: 'moebiusaxial' },
    { label: translations[currentLocale].axial2, scriptPath: '/vendor/math/mathCanvasMaster.js', value: 'moebiusaxial2' },
    { label: translations[currentLocale].biaxial, scriptPath: '/vendor/math/mathCanvasMaster.js', value: 'moebiusbiaxial' },
    { label: translations[currentLocale].bidirectional, scriptPath: '/vendor/math/mathCanvasMaster.js', value: 'moebiusbidi' },
    { label: translations[currentLocale].bidirectional2, scriptPath: '/vendor/math/mathCanvasMaster.js', value: 'moebiusbidi2' },
    { label: translations[currentLocale].bidirectional3, scriptPath: '/vendor/math/mathCanvasMaster.js', value: 'moebiusbidi3' },
    { label: translations[currentLocale].experimental1, scriptPath: '/vendor/math/mathCanvasMaster.js', value: 'moebiusexp' },
    { label: translations[currentLocale].experimental2, scriptPath: '/vendor/math/mathCanvasMaster.js', value: 'moebiusexp2' }


    ],
    "smiley": [
        { label: translations[currentLocale].twoD, scriptPath: '/vendor/math/mathCanvasMaster.js', value: 'standard' },
        { label: translations[currentLocale].threeD, scriptPath: '/vendor/math/mathCanvasMaster.js', value: 'standard3d' }
    ],







    "static": [
        { label: translations[currentLocale].plain, scriptPath: '/vendor/math/mathCanvasMaster.js', value: 'static' }
             ],
    // "dimension": [
     //   { label: "translations[currentLocale].plain", scriptPath: '/vendor/math/mathCanvasMaster.js', value: 'dimension' }
     //],
     "partialdimension": [
        { label: translations[currentLocale].plain, scriptPath: '/vendor/math/mathCanvasMaster.js', value: 'partialdimension' }
     ], "blossom": [
        { label: translations[currentLocale].plain, scriptPath: '/vendor/math/mathCanvasMaster.js', value: 'blossom' }
     ],

      "opera": [
        { label: translations[currentLocale].plain, scriptPath: '/vendor/math/mathCanvasMaster.js', value: 'opera' }

     ],

     "development": [
       // { label: translations[currentLocale].multiplication, scriptPath: '/vendor/math/mathCanvasMaster.js', value: 'multiplication' },
        { label: translations[currentLocale].wave, scriptPath: '/vendor/math/mathCanvasMaster.js', value: 'waveslide' },
        { label: translations[currentLocale].staticLabel, scriptPath: '/vendor/math/mathCanvasMaster.js', value: 'wavestatic' },
        { label: translations[currentLocale].circle, scriptPath: '/vendor/math/mathCanvasMaster.js', value: 'circle' }


     ],
     "experimental": [
        { label: "1", scriptPath: '/vendor/math/mathCanvasMaster.js', value: 'exp1' },
        { label: "2", scriptPath: '/vendor/math/mathCanvasMaster.js', value: 'exp2' },
        { label: "3", scriptPath: '/vendor/math/mathCanvasMaster.js', value: 'exp3' },
        { label: "4", scriptPath: '/vendor/math/mathCanvasMaster.js', value: 'exp4' },
        { label: "5", scriptPath: '/vendor/math/mathCanvasMaster.js', value: 'exp5' },
        { label: "6", scriptPath: '/vendor/math/mathCanvasMaster.js', value: 'exp6' },
        { label: "7", scriptPath: '/vendor/math/mathCanvasMaster.js', value: 'exp7' },
        { label: "8", scriptPath: '/vendor/math/mathCanvasMaster.js', value: 'exp8' },
        { label: "9", scriptPath: '/vendor/math/mathCanvasMaster.js', value: 'exp9' },
        { label: "10", scriptPath: '/vendor/math/mathCanvasMaster.js', value: 'exp10' }


     ]
};
// Entferne die aktive Klasse von allen Hauptkategorieelementen
const allCategoryElements = mainDropdownMenu.querySelectorAll('div');
allCategoryElements.forEach(el => {
    el.classList.remove('bg-gray-300', 'dark:bg-gray-500', 'border-black', 'dark:border-white');
    el.classList.add('border-gray-400', 'dark:border-gray-600', 'bg-white', 'dark:bg-gray-800'); // Setze die Standard-Hintergrundfarbe und -border für nicht ausgewählte Elemente
});

// Füge die aktive Klasse zum aktuellen Element hinzu
const activeCategoryElement = [...allCategoryElements].find(el => el.textContent.toLowerCase() === category);
if (activeCategoryElement) {
    activeCategoryElement.classList.add('bg-gray-300', 'dark:bg-gray-500', 'border-black', 'dark:border-white');
    activeCategoryElement.classList.remove('border-gray-400', 'dark:border-gray-600', 'bg-white', 'dark:bg-gray-800');
}

setupSubCategoryBoxes(subCategories[category]);
};

    mainDropdownButton.addEventListener('click', () => {
        mainDropdownMenu.classList.toggle('hidden');
    });

    subDropdownButton.addEventListener('click', () => {
        subDropdownMenu.classList.toggle('hidden');
    });

    document.addEventListener('click', (event) => {
        const isClickInsideMain = mainDropdownButton.contains(event.target) || mainDropdownMenu.contains(event.target);
        const isClickInsideSub = subDropdownButton.contains(event.target) || subDropdownMenu.contains(event.target);

        if (!isClickInsideMain) {
            mainDropdownMenu.classList.add('hidden');
        }
        if (!isClickInsideSub) {
            subDropdownMenu.classList.add('hidden');
        }
    });

    setupMainDropdownOptions();
});
